import { classNames } from 'Utilities/utils';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { useTenant } from 'Hooks/Hooks';
import { UPDATE_POLICY_PROFILE_POLICY } from 'Graph/typedQueries';
import { useMutation } from '@apollo/client';
import { PolicyWeight } from 'GeneratedGQL/graphql';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tooltip } from 'react-tooltip';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';

export interface RuleCardProps {
    name: string;
    weight: PolicyWeight;
    icon: JSX.Element;
    description: string;
    author: string;
    profileId: string;
    policyDocumentId: string;
}

type WeightDisplayAttributes = {
    name: string;
    color: string;
    textColor: string;
};

const weightDisplayAttributes: Record<PolicyWeight, WeightDisplayAttributes> = {
    POLICY_WEIGHT_DISABLED: { name: 'Disabled', color: 'bg-gray-400', textColor: 'text-gray-00' },
    POLICY_WEIGHT_LOW: { name: 'Low', color: 'bg-green-400', textColor: 'text-green-400' },
    POLICY_WEIGHT_MEDIUM: { name: 'Medium', color: 'bg-orange-500', textColor: 'text-yellow-400' },
    POLICY_WEIGHT_HIGH: { name: 'High', color: 'bg-red-500', textColor: 'text-red-400' },
    POLICY_WEIGHT_CRITICAL: { name: 'Critical', color: 'bg-blue-500', textColor: 'text-red-400' },
    POLICY_WEIGHT_UNKNOWN: { name: 'Unknown', color: 'bg-gray-400', textColor: 'text-gray-400' },
};

export const FactorCard = ({
    name,
    weight,
    icon,
    description,
    author,
    profileId,
    policyDocumentId,
}: RuleCardProps): JSX.Element => {
    const { enableFactorWeightConfig, enableNewPolicyModal } = useFlags();
    const tenantId = useTenant();

    const [updatePolicyProfilePolicy, { loading: loadingUpdateProfilePolicy }] =
        useMutation(UPDATE_POLICY_PROFILE_POLICY);

    const loading = loadingUpdateProfilePolicy;

    const [selected, setSelected] = useState(weight);

    useEffect(() => {
        async function update() {
            if (selected !== weight) {
                await updatePolicyProfilePolicy({
                    variables: {
                        tenantId: tenantId || '',
                        policyProfileId: profileId || '',
                        policy: {
                            policyId: policyDocumentId,
                            weight: selected,
                        },
                    },
                    refetchQueries: ['listPolicyProfiles', 'getPolicyProfile'],
                    awaitRefetchQueries: true,
                });
            }
        }
        update();
    }, [policyDocumentId, profileId, selected, tenantId, updatePolicyProfilePolicy, weight]);

    const displayName = useMemo(() => {
        if (enableNewPolicyModal) {
            return name.split(' -- ')[1];
        } else {
            return name;
        }
    }, [name, enableNewPolicyModal]);

    return (
        <div key={name} className="py-1 relative">
            <div className="ml-6 pl-6 bg-gray-700 rounded-md">
                <div
                    className={classNames(
                        selected === PolicyWeight.PolicyWeightDisabled
                            ? 'bg-gray-600 border-gray-500 border-2'
                            : 'bg-gray-600 border-blue-700 border-2',
                        'transition rounded-full shadow-lg absolute left-0 top-7 h-12 w-12 text-white flex items-center justify-center',
                    )}
                >
                    <div className="h-6 w-6">{icon}</div>
                </div>
                <div className="p-4 relative flex justify-between items-center">
                    <div>
                        <h4 className="text-md font-semibold mb-1 text-white mr-6">{displayName}</h4>
                        <p className="text-white mb-1 text-xs">{description}</p>
                        <p className="text-gray-400 mb-1 text-xs">Managed by {author}</p>
                    </div>
                    <div>
                        {enableFactorWeightConfig ? (
                            <div className="space-x-4 flex justify-end items-center">
                                {loading && <span className="h-4 w-4 mt-1 loader" />}
                                <WeightSelector selected={selected} setSelected={setSelected} />
                            </div>
                        ) : (
                            <div
                                className="text-xs text-right flex items-center cursor-help"
                                data-tooltip-id="weight-tooltip"
                            >
                                {weightDisplayAttributes[selected].name} Weight
                                <span
                                    className={classNames(
                                        'inline-block h-2 w-2 rounded-full ml-2',
                                        weightDisplayAttributes[selected].color,
                                    )}
                                />
                                <QuestionMarkCircleIcon className="h-4 w-4 text-gray-400 ml-2" />
                                <Tooltip id="weight-tooltip" className="bg-gray-800" disableStyleInjection={'core'}>
                                    <div className="flex text-left text-xs w-128">
                                        When this factor is matched, the trust score for this session will be impacted
                                        relative to the combined factor weight and score.
                                        <br />
                                        <br />
                                        The trust score may be incremented or decremented based on factor conditions and
                                        the weight determines the degree of variation applied.
                                        <br />
                                        <br />
                                        If the factor weight is critical and high risk conditions are met, the trust
                                        score will be set to zero, independent of any other factor scores applied.
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                        {/* <div className="text-xs text-gray-400 pt-2">Factor will impact trust score by this weight</div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

interface WeightSelectorProps {
    selected: PolicyWeight;
    setSelected: React.Dispatch<React.SetStateAction<{}>>;
}

const configurableWeights = Object.entries(weightDisplayAttributes).filter(([weight]) => {
    return weight !== 'POLICY_WEIGHT_UNKNOWN';
});

const WeightSelector = ({ selected, setSelected }: WeightSelectorProps): JSX.Element => {
    const attributes = weightDisplayAttributes[selected];

    return (
        <Listbox
            value={selected}
            onChange={(s: PolicyWeight) => {
                setSelected(s);
            }}
        >
            {({ open }) => (
                <>
                    <div className="mt-1 relative">
                        <Listbox.Button className="relative w-52 bg-gray-600 text-gray-200 border border-gray-700 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                            <div className="flex items-center">
                                <span
                                    className={classNames(
                                        attributes.color,
                                        'flex-shrink-0 inline-block h-2 w-2 rounded-full',
                                    )}
                                />
                                <span className="ml-3 block truncate text-xs">{attributes.name}</span>
                            </div>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 bg-gray-600 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-gray-200 ring-opacity-5 overflow-auto focus:outline-none sm:text-sm w-52">
                                {configurableWeights.map(([weight, attributes]) => (
                                    <Listbox.Option
                                        key={weight}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'text-gray-200 bg-blue-800' : 'text-gray-300',
                                                'cursor-default select-none relative py-2 pl-3 pr-9',
                                            )
                                        }
                                        value={weight}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center text-xs">
                                                    <span
                                                        className={classNames(
                                                            attributes.color,
                                                            'flex-shrink-0 inline-block h-2 w-2 rounded-full',
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    <span
                                                        className={classNames(
                                                            selected ? 'font-normal' : 'font-normal',
                                                            'ml-3 block truncate text-xs',
                                                        )}
                                                    >
                                                        {attributes.name}
                                                    </span>
                                                </div>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : 'text-blue-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
};
