import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_LOCATION_GRID, GET_LOCATION_GRID_X_Y } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
// Geo sourced from https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries-sans-antarctica.json
import WorldCountriesGeos from './WorldCountriesGeos.json';
import { useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NoSymbolIcon } from '@heroicons/react/24/solid';
import { Node } from 'Types/types';
import { useGraphControls } from 'Hooks/GraphHooks';
import { renderToString } from 'react-dom/server';

type GridCol = {
    Cols: number[] | null;
    __typename: string;
};

type GridData = {
    grid: GridCol[];
    gridLevel: number;
    columns: number;
    rows: number;
    xValues: number[];
    yValues: number[];
    __typename: string;
};

export const WorldMapChart = ({
    startDate,
    endDate,
    height,
}: {
    startDate: number;
    endDate: number;
    height: string;
}) => {
    const tenantId = useTenant();
    const { addNodeToExplorer } = useGraphControls();
    const {
        enableWorldMap,
        enableAdvancedQueries,
        enableWorldMapChunkLoading,
        worldMapChunkCount,
        worldMapSubstituteFailedGridSquaresWithMaxValue,
    } = useFlags();

    const [geojson, setGeojson] = useState<GeoJSON | undefined>();

    const { loading, error, data } = useQuery(GET_LOCATION_GRID, {
        variables: { tenantId, startTimeInMs: startDate, endTimeInMs: endDate },
        skip: !enableWorldMap || enableWorldMapChunkLoading,
    });

    const [loadingChunked, setLoadingChunked] = useState(false);
    const [errorChunked, setErrorChunked] = useState(false);

    const [getLocationGrid] = useLazyQuery(GET_LOCATION_GRID_X_Y);

    const calculateMetaGrid = useCallback(async () => {
        // The master grid is 32x32
        // The meta grid is 4x4 (where each meta grid contains 8x8 cells from the master grid)
        // This function will retrieve each meta grid chunk and then piece together the master grid

        const metaGridSize = worldMapChunkCount; // Size of the meta grid (default: 4x4)
        const masterGridSize = 32; // Size of the master grid (32x32)
        const chunkSize = masterGridSize / metaGridSize; // Size of each chunk in the master grid (default: 8x8)

        // Initialize the master grid
        const masterGrid: number[][] = Array.from({ length: masterGridSize }, () => Array(masterGridSize).fill(0));

        // Function to update the master grid with a chunk of data
        const updateMasterGrid = (gridData: GridData, metaRow: number, metaCol: number) => {
            for (let row = 0; row < chunkSize; row++) {
                for (let col = 0; col < chunkSize; col++) {
                    const masterRow = metaRow * chunkSize + row;
                    let masterCol = metaCol * chunkSize + col;
                    masterCol = masterCol >= chunkSize ? masterCol - 1 : masterCol;

                    if (gridData.grid[row]) {
                        const requiredCol = gridData.grid[row].Cols;

                        if (requiredCol) {
                            const cellValue = requiredCol[col];

                            console.log('master row', masterRow, 'master col', masterCol, 'cell value', cellValue);
                            masterGrid[masterRow][masterCol] = cellValue;
                        }
                    }
                }
            }
        };

        setLoadingChunked(true);

        // Populate from bottom left to top right
        for (let i = metaGridSize - 1; i >= 0; i--) {
            for (let j = 0; j < metaGridSize; j++) {
                const xValues = [
                    -180 + (360 / masterGridSize) * chunkSize * j,
                    -180 + (360 / masterGridSize) * chunkSize * (j + 1),
                ];
                const yValues = [
                    -90 + (180 / masterGridSize) * chunkSize * (metaGridSize - i - 1),
                    -90 + (180 / masterGridSize) * chunkSize * (metaGridSize - i),
                ];

                try {
                    const result = await getLocationGrid({
                        variables: {
                            tenantId,
                            startTimeInMs: startDate,
                            endTimeInMs: endDate,
                            gridLevel: 2,
                            xValues: xValues,
                            yValues: yValues,
                        },
                    });

                    if (result.data) {
                        if (result.data.getLocationGrid.grid) {
                            console.log('meta row', i, 'meta col', j, 'data', result.data.getLocationGrid);
                            updateMasterGrid(result.data.getLocationGrid, i, j);
                            const geoJson = createGeoJSONFromGridChunked(masterGrid, -90, 90, -180, 180);
                            setGeojson(geoJson);
                        } else {
                            console.log('meta row', i, 'meta col', j, 'empty');
                        }
                    } else {
                        if (worldMapSubstituteFailedGridSquaresWithMaxValue) {
                            console.log('meta row', i, 'meta col', j, 'failed - substituting with max value');

                            const grid = {
                                grid: Array.from({ length: chunkSize }, () => {
                                    return { Cols: Array(chunkSize).fill(-1) };
                                }),
                            } as GridData;

                            updateMasterGrid(grid, i, j);
                            const geoJson = createGeoJSONFromGridChunked(masterGrid, -90, 90, -180, 180);
                            setGeojson(geoJson);
                        } else {
                            console.log('meta row', i, 'meta col', j, 'failed');
                        }
                    }
                } catch (e) {
                    console.log(e);
                    setErrorChunked(true);
                    setLoadingChunked(false);
                }
            }
        }

        const geoJson = createGeoJSONFromGridChunked(masterGrid, -90, 90, -180, 180);
        setGeojson(geoJson);
        console.log(masterGrid);
        console.log(geoJson);
        console.log('Finished loading chunked map');
        setLoadingChunked(false);
    }, [
        endDate,
        getLocationGrid,
        startDate,
        tenantId,
        worldMapChunkCount,
        worldMapSubstituteFailedGridSquaresWithMaxValue,
    ]);

    useEffect(() => {
        if (enableWorldMap && enableWorldMapChunkLoading) {
            console.log('Loading chunked map using chunk count', worldMapChunkCount);
            calculateMetaGrid();
        }
    }, [calculateMetaGrid, enableWorldMap, enableWorldMapChunkLoading, worldMapChunkCount]);

    useEffect(() => {
        if (data) {
            console.log('Loading standard map');
            const grid = data.getLocationGrid.grid;
            if (grid) {
                const geoJson = createGeoJSONFromGrid(grid, -90, 90, -180, 180);
                setGeojson(geoJson);
            }
        }
    }, [data]);

    return (
        <div className="relative">
            {(loading || loadingChunked) && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-70">
                    <div className="-top-10 loader h-4 w-4"></div>
                </div>
            )}
            {(error || errorChunked) && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-70">
                    <NoSymbolIcon
                        className="-mt-20 h-6 w-6 text-red-400"
                        data-tooltip-id="heatmap"
                        data-tooltip-content="There was an error retrieving location information"
                    />
                </div>
            )}
            <ComposableMap
                id="map"
                projection="geoMercator"
                projectionConfig={{
                    scale: 180,
                    rotate: [-10, 0, 0],
                    center: [0, 18],
                }}
                style={{ width: '100%', height: height }}
            >
                <ZoomableGroup>
                    <Geographies geography={WorldCountriesGeos}>
                        {({ geographies }) =>
                            geographies.map((geo) => {
                                const fill = '#6B7280';
                                const strokeWidth = 0.5;

                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill={fill}
                                        fillOpacity={0.05}
                                        stroke="#9DA3AD"
                                        strokeWidth={strokeWidth}
                                        style={{
                                            default: { outline: 'none' },
                                            hover: { outline: 'none' },
                                            pressed: { outline: 'none' },
                                        }}
                                    />
                                );
                            })
                        }
                    </Geographies>

                    <Geographies geography={geojson}>
                        {({ geographies }) =>
                            geographies.map((geo) => {
                                const {
                                    value,
                                    maxValue,
                                    row,
                                    col,
                                    opacity,
                                    centerLat,
                                    centerLon,
                                    radius,
                                    coordinates,
                                } = geo.properties;
                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        data-tooltip-id={'heatmap'}
                                        data-tooltip-html={renderToString(
                                            <div className="text-center">
                                                <div>{value > 0 ? value : `Over ${maxValue}`} Events</div>
                                                {enableAdvancedQueries && (
                                                    <div className="text-gray-400">Click to add to Explorer</div>
                                                )}
                                            </div>,
                                        )}
                                        geography={geo}
                                        fill={'#36a8fa'}
                                        fillOpacity={value > 0 ? opacity : 0.8}
                                        stroke="#36a8fa"
                                        strokeOpacity={value > 0 ? opacity + 0.1 : 0.9}
                                        className="cursor-pointer"
                                        style={{
                                            default: { outline: 'none' },
                                            hover: { outline: 'none' },
                                            pressed: { outline: 'none' },
                                        }}
                                        onMouseEnter={() => {
                                            console.log(
                                                `Row: ${row}, Column: ${col}, Value: ${value}, Opacity: ${opacity}`,
                                            );
                                        }}
                                        onClick={() => {
                                            if (enableAdvancedQueries) {
                                                // Round the center lat and lon to 4 decimal places, convert the radius to kilometers
                                                const name = `${centerLat.toFixed(4)}, ${centerLon.toFixed(
                                                    4,
                                                )}, Radius ${(radius / 1000).toFixed()} km`;

                                                const node: Node = {
                                                    id: `heatmap-${row}-${col}`,
                                                    label: 'query',
                                                    name: name,
                                                    queryAttributes: {
                                                        location: {
                                                            latitude: centerLat,
                                                            longitude: centerLon,
                                                            radius: radius,
                                                            coordinates,
                                                        },
                                                    },
                                                    x: 0,
                                                    y: 0,
                                                    props: { displayName: name },
                                                    tags: [],
                                                    attributes: [],
                                                    links: [],
                                                    neighbors: [],
                                                };

                                                addNodeToExplorer(node);
                                            }

                                            console.log(
                                                `Added Row: ${row}, Column: ${col}, Value: ${value}, Name: ${name}`,
                                            );
                                        }}
                                    />
                                );
                            })
                        }
                    </Geographies>
                </ZoomableGroup>
            </ComposableMap>{' '}
            <Tooltip id="heatmap" disableStyleInjection={'core'} />
        </div>
    );
};

export type GridType = {
    Cols: number[] | null;
}[];

export type GeoJSON = {
    type: string;
    features: {
        type: string;
        properties: {
            value: number;
            maxValue: number;
            row: number;
            col: number;
            opacity?: number;
        };
        geometry: {
            type: string;
            coordinates: number[][][];
        };
    }[];
};

export const createGeoJSONFromGrid = (
    grid: GridType,
    yStart: number,
    yEnd: number,
    xStart: number,
    xEnd: number,
): GeoJSON => {
    const numRows = 32;
    const numCols = 32;

    const features = [];

    const yStep = (yEnd - yStart) / numRows;
    const xStep = (xEnd - xStart) / numCols;

    // Find minimum and maximum values across all cells
    let minValue = Infinity;
    let maxValue = -Infinity;
    let maxAbsValue = -Infinity;

    for (let i = 0; i < numRows; i++) {
        const row = grid[i];

        if (row.Cols) {
            for (let j = 0; j < numCols; j++) {
                const cellValue = row.Cols[j];
                // Ignore null and non-positive values
                if (cellValue !== null && cellValue > 0) {
                    const logValue = Math.log(cellValue);

                    maxAbsValue = Math.max(maxAbsValue, cellValue);

                    // Set min and max to logValue if they're null
                    if (minValue === null || maxValue === null) {
                        minValue = logValue;
                        maxValue = logValue;
                    } else {
                        minValue = Math.min(minValue, logValue);
                        maxValue = Math.max(maxValue, logValue);
                    }
                }
            }
        }
    }

    for (let i = 0; i < numRows; i++) {
        const row = grid[i];

        if (row.Cols) {
            for (let j = 0; j < numCols; j++) {
                const cellValue = row.Cols[j];

                if (cellValue !== null && (cellValue > 0 || cellValue === -1)) {
                    const top = yEnd - i * yStep;
                    const left = xStart + j * xStep;
                    const bottom = top - yStep;
                    const right = left + xStep;

                    const centerLat = top - yStep / 2;
                    const centerLon = left + xStep / 2;

                    // Calculate the meter diagonal of the square using the top-left and bottom-right corners
                    const diagonal = Math.sqrt(
                        Math.pow(111000 * (top - bottom), 2) + Math.pow(111000 * (right - left), 2),
                    );

                    // The radius of the circumscribed circle is half the diagonal
                    const radius = diagonal / 2;

                    // Calculate opacity based on cellValue
                    let opacity = 0.8;
                    if (cellValue > 0) {
                        const logValue = Math.log(cellValue);
                        opacity = 0.2 + ((logValue - minValue) / (maxValue - minValue)) * 0.6 || 0.8;
                    }

                    const coordinates = [
                        [
                            [left, top], // Starting point
                            [right, top], // Over to top-right
                            [right, bottom], // Down to bottom-right
                            [left, bottom], // Over to bottom-left
                            [left, top], // Back to starting point
                        ],
                    ];

                    features.push({
                        type: 'Feature',
                        properties: {
                            value: cellValue,
                            maxValue: maxAbsValue,
                            row: i,
                            col: j,
                            opacity: opacity,
                            centerLat: centerLat,
                            centerLon: centerLon,
                            radius: radius,
                            coordinates,
                        },
                        geometry: {
                            type: 'Polygon',
                            coordinates,
                        },
                    });
                }
            }
        }
    }

    return {
        type: 'FeatureCollection',
        features,
    };
};

export const createGeoJSONFromGridChunked = (
    grid: number[][],
    yStart: number,
    yEnd: number,
    xStart: number,
    xEnd: number,
): GeoJSON => {
    const numRows = 32;
    const numCols = 32;

    const features = [];

    const yStep = (yEnd - yStart) / numRows;
    const xStep = (xEnd - xStart) / numCols;

    // Find minimum and maximum values across all cells
    let minValue = Infinity;
    let maxValue = -Infinity;
    let maxAbsValue = -Infinity;

    for (let i = 0; i < numRows; i++) {
        const row = grid[i];

        for (let j = 0; j < numCols; j++) {
            const cellValue = row[j];
            // Ignore null and non-positive values
            if (cellValue !== null && cellValue > 0) {
                maxAbsValue = Math.max(maxAbsValue, cellValue);

                const logValue = Math.log(cellValue);

                // Set min and max to logValue if they're null
                if (minValue === null || maxValue === null) {
                    minValue = logValue;
                    maxValue = logValue;
                } else {
                    minValue = Math.min(minValue, logValue);
                    maxValue = Math.max(maxValue, logValue);
                }
            }
        }
    }

    for (let i = 0; i < numRows; i++) {
        const row = grid[i];

        for (let j = 0; j < numCols; j++) {
            const cellValue = row[j];

            if (cellValue !== null && (cellValue > 0 || cellValue === -1)) {
                const top = yEnd - i * yStep;
                const left = xStart + j * xStep;
                const bottom = top - yStep;
                const right = left + xStep;

                const centerLat = top - yStep / 2;
                const centerLon = left + xStep / 2;

                // Calculate the meter diagonal of the square using the top-left and bottom-right corners
                const diagonal = Math.sqrt(Math.pow(111000 * (top - bottom), 2) + Math.pow(111000 * (right - left), 2));

                // The radius of the circumscribed circle is half the diagonal
                const radius = diagonal / 2;

                // Calculate opacity based on cellValue
                let opacity = 0.8;
                if (cellValue > 0) {
                    const logValue = Math.log(cellValue);
                    opacity = 0.2 + ((logValue - minValue) / (maxValue - minValue)) * 0.6 || 0.8;
                }

                const coordinates = [
                    [
                        [left, top], // Starting point
                        [right, top], // Over to top-right
                        [right, bottom], // Down to bottom-right
                        [left, bottom], // Over to bottom-left
                        [left, top], // Back to starting point
                    ],
                ];

                features.push({
                    type: 'Feature',
                    properties: {
                        value: cellValue,
                        maxValue: maxAbsValue,
                        row: i,
                        col: j,
                        opacity: opacity,
                        centerLat: centerLat,
                        centerLon: centerLon,
                        radius: radius,
                        coordinates,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates,
                    },
                });
            }
        }
    }

    return {
        type: 'FeatureCollection',
        features,
    };
};
