import { capitalize, classNames, wallTypeLookup } from 'Utilities/utils';
import { GET_WALL_EVENTS } from 'Graph/queries';
import { formatRelative } from 'date-fns';
import { TimeIntervalProps } from 'Types/types';

import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTenant } from 'Hooks/Hooks';

import { useGraphControls } from 'Hooks/GraphHooks';
import { List, ListItem } from 'Library/List';
import { WallEvent, getWallProviderIcon } from './TheWallComponents';
import { Tooltip } from 'react-tooltip';

const defaultLimit = 15;

export const TheWallInsights = ({ startDate, endDate }: TimeIntervalProps) => {
    const tenantId = useTenant();

    const { addNodeToExplorer } = useGraphControls();

    const [wallEvents, setWallEvents] = useState<WallEvent[]>([]);

    const [fetchCount, setFetchCount] = useState(0);
    const [limit, setLimit] = useState(defaultLimit);

    const increaseLimit = () => {
        setLimit(limit + defaultLimit);
    };

    const { loading, error, data } = useQuery(GET_WALL_EVENTS, { variables: { tenantId, startDate, endDate, limit } });

    const loadingFirstTime = loading && limit === defaultLimit;
    const loadingMore = loading && limit > defaultLimit;

    useEffect(() => {
        if (data) {
            if (data.getWallEvents && data.getWallEvents.events) {
                setWallEvents([...data.getWallEvents.events]);
                setFetchCount((prev) => prev + 1);
            }
        }
    }, [data]);

    return (
        <div className="h-full">
            <Tooltip id="wall" disableStyleInjection={'core'} />
            <List title="Activity Wall" loading={loadingFirstTime} error={Boolean(error)}>
                {wallEvents.length > 0 && (
                    <div
                        className={classNames(
                            'overflow-y-auto flex',
                            limit > defaultLimit ? (fetchCount > 1 ? 'flex-col-reverse' : 'flex-col') : 'flex-col',
                            loading && limit === defaultLimit ? '' : 'h-9000',
                        )}
                    >
                        <div className="space-y-1">
                            {wallEvents.slice(0, limit).map((event) => {
                                return (
                                    <div key={event.key}>
                                        <ListItem
                                            key={event.key}
                                            title={wallTypeLookup(event.type)}
                                            description={event.displayName || event.alternateId}
                                            secondDescription={capitalize(
                                                formatRelative(event.publishedAt, new Date()),
                                            )}
                                            height="h-14"
                                            icon={
                                                <img
                                                    src={getWallProviderIcon(event.sourceProcessorType)}
                                                    className="h-6 w-6"
                                                />
                                            }
                                            onClick={() => {
                                                const node = {
                                                    id: event.nodeId,
                                                    name: event.displayName,
                                                    x: 0,
                                                    y: 0,
                                                    links: [],
                                                    neighbors: [],
                                                    group: 'actor',
                                                    label: 'actor',
                                                    props: {
                                                        displayName: event.displayName,
                                                        alternateId: event.alternateId,
                                                    },
                                                    tags: [],
                                                    attributes: [],
                                                };
                                                addNodeToExplorer(node, false, true);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </List>
            {!loadingMore && !loadingFirstTime && wallEvents.length > 0 && (
                <div>
                    {wallEvents.length == limit ? (
                        <div className="flex w-full items-center justify-center text-gray-500">
                            <button
                                className="rounded-md hover:bg-gray-500 hover:text-gray-100 px-2 py-2 flex items-center justify-center"
                                onClick={increaseLimit}
                            >
                                Load More...
                            </button>
                        </div>
                    ) : (
                        <div className="h-8 flex items-center justify-center text-gray-600">All results loaded</div>
                    )}
                </div>
            )}
            {loadingMore && (
                <div className="flex w-full items-center justify-center text-gray-500">
                    <div className="flex items-center justify-center w-8 h-8">
                        <div className="loader h-4 w-4" />
                    </div>
                </div>
            )}
        </div>
    );
};
