// React Dependencies
import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';

// Page information and metadata
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Favicon from 'Utilities/Favicon';
import { FloatingPortalRoot } from 'Utilities/FloatingPortalRoot';
import 'react-tooltip/dist/react-tooltip.css';

// Higher Order Components for services
import UtilitiesAndProviders from 'Utilities/UtilitiesAndProviders';
import AuthenticationProvider from 'Utilities/AuthenticationProvider';
import ProtectedRoute from 'Utilities/ProtectedRoute';

import AuthenticatedLaunchDarklyProvider from 'Utilities/AuthenticatedLaunchDarklyProvider';

import PublicApolloProvider from 'Utilities/PublicApolloProvider';
import AuthorizedApolloProvider from './Utilities/AuthorizedApolloProvider';

// Identity Risk public components
import Terms from 'Onboarding/Terms';
import NoRoles from 'Onboarding/NoRoles';
import Login from 'Login/Login';

// Identity Risk app components
import Welcome from './Onboarding/Welcome/WelcomeFlow';
import IdentityMap from './Map/IdentityMap';

import { clearLocalCaches } from 'Utilities/utils';
import { OrganizationProvider } from 'Utilities/OrganizationProvider';

// History initialization
export const history = createBrowserHistory();

// Clear local caches to reset any stale state
clearLocalCaches();

// Create and serve the Identity Risk application
function App(): JSX.Element {
    return (
        <Router history={history}>
            <HelmetProvider>
                <Helmet titleTemplate="%s | SailPoint Identity Risk" defaultTitle="SailPoint Identity Risk" />
                <Favicon />
                <OrganizationProvider>
                    <Switch>
                        <Route path="/public">
                            <Switch>
                                <Route path="/public/terms" component={Terms} />
                            </Switch>
                        </Route>
                        <Route path="/auth">
                            <PublicApolloProvider>
                                <Switch>
                                    <Route path="/auth/login" component={Login} />
                                </Switch>
                            </PublicApolloProvider>
                        </Route>
                        <Route>
                            <AuthenticationProvider>
                                <AuthenticatedLaunchDarklyProvider>
                                    <AuthorizedApolloProvider>
                                        <UtilitiesAndProviders>
                                            <Switch>
                                                <ProtectedRoute path="/account-activated" component={NoRoles} />
                                                <ProtectedRoute path="/welcome" component={Welcome} />
                                                <ProtectedRoute path="/identity-map" component={IdentityMap} />
                                                <ProtectedRoute path="/" component={IdentityMap} />
                                                <FloatingPortalRoot />
                                            </Switch>
                                        </UtilitiesAndProviders>
                                    </AuthorizedApolloProvider>
                                </AuthenticatedLaunchDarklyProvider>
                            </AuthenticationProvider>
                        </Route>
                    </Switch>
                </OrganizationProvider>
            </HelmetProvider>
        </Router>
    );
}

export default App;
